import React from "react"

import { Layout, SEO } from "../components"
import TermAndCondations from "../components/terms-and-conditions/term-conditions-main"

const Terms = () => {
  return (
    <Layout>
      <SEO title="Terms & Conditions" />
      <TermAndCondations />
    </Layout>
  )
}

export default Terms
